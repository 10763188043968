import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router, NavigationEnd} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import routesConstants from '../constants/routes.constants';

@Injectable({
  providedIn: 'root', // Makes this service available throughout the application
})
export class NavigationService {
  private currentScreenSubject = new BehaviorSubject<string>(this.getStoredScreen() || 'home'); // Default screen with localStorage retrieval
  currentScreen$ = this.currentScreenSubject.asObservable(); // Observable to subscribe to screen changes

  // Mapping of screens to their indexes
  private screenIndexMap: { [key: string]: number } = {
    'home': 0,
    'agenda': 1,
    'garden': 2,
    'profile': 3
  };

  constructor(private location: Location, private router: Router) {
    // Listen to navigation end events
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const route = event.urlAfterRedirects.startsWith('/') ? event.urlAfterRedirects.slice(1) : event.urlAfterRedirects;
        if (this.hasScreen(route)) {
          this.changeScreen(route);
        }
      }
    });
  }

  /**
   * Navigates back to the previous location in the history stack.
   */
  goBack(): void {
    const currentPath = this.router.url.split('?')[0]; // Get the path without query params
    if (currentPath) {
      // Navigate to the same path without the query parameters
      this.router.navigate([currentPath]).then(() => {
        // After navigating to the path without query params, use location.back()
        // to ensure you're navigating to the actual previous page
        if (currentPath.split('/')[2] === 'seed') {
          this.router.navigate([routesConstants.GARDEN_SEEDS])
        } else if (currentPath.split('/')[2] === 'cultivation') {
          this.router.navigate([routesConstants.GARDEN_LIST])
        } else {
          this.location.back();
        }
      });
    } else {
      // If no query params, just go back normally
      this.location.back();
    }
  }


  /**
   * Method to change the current screen and store it in local storage
   */
  changeScreen(screen: string): void {
    if (this.hasScreen(screen)) {
      this.currentScreenSubject.next(screen);
      localStorage.setItem('currentScreen', screen); // Store in local storage
    }
  }

  /**
   * Retrieve the stored screen from local storage
   */
  getStoredScreen(): string | null {
    return localStorage.getItem('currentScreen');
  }

  /**
   * Check if the screen exists in the mapping
   */
  hasScreen(screen: string): boolean {
    return this.screenIndexMap.hasOwnProperty(screen);
  }

  /**
   * Get the screen index map
   */
  getScreenIndexMap(): { [key: string]: number } {
    return this.screenIndexMap;
  }

  getCurrentIndex(): number {
    const currentScreen = this.currentScreenSubject.getValue();
    return this.screenIndexMap[currentScreen] || -1; // Return -1 if not found
  }
}

